import { useStaticQuery, graphql } from "gatsby";
import { MARKET_GLOBAL_ENDPOINT } from "../../../data/coingecko-endpoints";
import { MarketGlobalQuery } from "../../../graphql-types";
import { mapMarketGlobal } from "../../utils/data-mappings/map-market-global";
import { MarketGlobalResult } from "../../../types/api-results/market-global";
import { FETCH_INTERVAL } from "../../constants/data-fetch";
import { useAxios } from "./use-axios";

export const useMarketGlobal = () => {
  const staticData = useStaticQuery<MarketGlobalQuery>(
    graphql`
      query MarketGlobal {
        marketGlobal {
          total_market_cap {
            usd
            aud
            eth
            btc
          }
          market_cap_percentage {
            btc
          }
          market_cap_change_percentage_24h_usd
        }
      }
    `
  );

  const { data, error, loading, timestamp } = useAxios<{
    data: MarketGlobalResult;
  }>(MARKET_GLOBAL_ENDPOINT, {
    refetchInterval: FETCH_INTERVAL,
  });

  const rawData = data?.data || staticData?.marketGlobal || undefined;

  return {
    data: mapMarketGlobal(rawData),
    error,
    loading,
    timestamp,
  };
};
