import { getExchangeListQueryUrl } from "../../../data/coingecko-endpoints";
import { DEFI_EXCHANGES } from "../../../data/defi-exchanges";
import { EXCHANGE_NAMES } from "../../../data/exchange-names";
import { ExchangeListItemResult } from "../../../types/api-results/exchange-list-item";
import { FETCH_INTERVAL } from "../../constants/data-fetch";
import { mapExchangeListItems } from "../../utils/data-mappings/map-exchange-list-item";
import { useAxios } from "./use-axios";

interface UseExchangeListInput {
  staticData?: ExchangeListItemResult[];
  size?: number;
  page?: number;
  orderBy?: "volume" | "trust_score";
  isDex?: boolean;
}

export const useExchangeList = ({
  staticData,
  size = 100,
  page = 1,
  orderBy = "trust_score",
  isDex,
}: UseExchangeListInput) => {
  const { data: page1Data, loading: page1Loading } = useAxios<
    ExchangeListItemResult[]
  >(
    getExchangeListQueryUrl({
      pageSize: 250,
      page: 1,
    }),
    {
      refetchInterval: FETCH_INTERVAL,
    }
  );

  const { data: page2Data = [] } = useAxios<ExchangeListItemResult[]>(
    getExchangeListQueryUrl({
      pageSize: 250,
      page: 2,
    }),
    {
      refetchInterval: FETCH_INTERVAL,
    }
  );

  const { data: page3Data = [] } = useAxios<ExchangeListItemResult[]>(
    getExchangeListQueryUrl({
      pageSize: 250,
      page: 3,
    }),
    {
      refetchInterval: FETCH_INTERVAL,
    }
  );

  const liveData = page1Data
    ? [...page1Data, ...page2Data, ...page3Data]
    : undefined;
  const rawData = liveData || staticData || undefined;

  const allowedList = rawData?.filter(
    (exchange) =>
      exchange.name &&
      (DEFI_EXCHANGES.includes(exchange.name) ||
        EXCHANGE_NAMES.includes(exchange.name))
  );

  const mappedData = mapExchangeListItems(allowedList)?.sort((a, b) => {
    if (orderBy === "volume") {
      return (b.tradeVolume24hBtc || 0) - (a.tradeVolume24hBtc || 0);
    }
    if (orderBy === "trust_score") {
      return (a.trustScoreRank || 0) - (b.trustScoreRank || 0);
    }
    return 0;
  });

  const filteredData = isDex
    ? mappedData?.filter((item) => item.isDex)
    : mappedData;

  return {
    data: filteredData?.slice((page - 1) * size, page * size),
    loading: page1Loading,
    liveDataInitialLoading: page1Loading && !page1Data,
  };
};
