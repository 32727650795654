export const EXCHANGE_NAMES = [
  "Binance",
  "Coinbase Pro",
  "Kraken",
  "Gate.io",
  "Binance US",
  "P2PB2B",
  "Bitstamp",
  "Bitfinex",
  "KuCoin",
  "Bithumb Global",
  "Poloniex",
  "Bittrex",
  "Bitkub",
  "Max Maicoin",
  "BitoPro",
  "OKEx Korea",
  "Huobi Global",
  "eToroX",
  "Bithumb",
  "WhiteBIT",
  "Coinsbit",
  "BitMax",
  "Liquid",
  "bitFlyer",
  "BtcTurk PRO",
  "Crypto.com",
  "Gemini",
  "EXMO",
  "Coinlist",
  "AAX",
  "Bitso",
  "Luno",
  "Atomars",
  "Indodax",
  "Zaif",
  "CEX.IO",
  "Nominex",
  "B2BX",
  "Bitpanda Pro",
  "TokensNet",
  "BTCMarkets",
  "NiceHash",
  "OKEx",
  "ZB",
  "BigONE",
  "BKEX",
  "Hotbit",
  "MXC",
  "HitBTC",
  "ProBit",
  "FTX",
  "Coinone",
  "Paribu",
  "AEX",
  "Bitbank",
  "Bitrue",
  "BitMart",
  "Coincheck",
  "Oceanex",
  "DODO",
  "GMO Japan",
  "OKCoin",
  "Coinfloor",
  "AlterDice",
  "CoinJar Exchange",
  "Independent Reserve",
  "Bamboo Relay",
  "Kuna Exchange",
  "Namebase",
  "CoinDCX",
  "Huobi Thailand",
  "Uniswap (v2)",
  "Upbit",
  "BW.com",
  "CoinTiger",
  "Bitforex",
  "LATOKEN",
  "Bilaxy",
  "Decoin",
  "Phemex",
  "BitBay",
  "Sushiswap",
  "Balancer",
  "Coindeal",
  "Vebitcoin",
  "WazirX",
  "Currency.com",
  "BitBNS",
  "Waves.Exchange",
  "Mercado Bitcoin",
  "dYdX",
  "BTSE",
  "Korbit",
  "Mooniswap",
  "DragonEx",
  "Nash",
  "Deversifi",
  "Dex-Trade",
  "TheRockTrading",
  "Narkasa",
  "BiKi",
  "Digifinex",
  "Hoo.com",
  "Tokenize",
  "Bitget",
  "Bibox",
  "CREX24",
  "IDCM",
  "VCC Exchange",
  "Emirex",
  "BuyUcoin",
  "Nash",
  "C2CX",
  "TOKOK",
  "Tokenomy",
  "Altilly",
  "NovaDAX",
  "Vinex",
  "GokuMarket",
  "Binance DEX",
  "Bamboo Relay",
  "TomoDEX",
  "BepSwap",
  "Satang Pro",
  "Bitoffer",
  "Deversifi",
  "Hanbitco",
  "TheRockTrading",
  "Omgfin",
  "Mercado Bitcoin",
  "ChainEX",
  "Serum DEX",
  "TradeOgre",
  "Switcheo",
  "Ovex",
  "SouthXchange",
  "CoinHe",
  "Cream Swap",
  "qTrade",
  "Coinfalcon",
  "Beaxy",
  "Coingi",
  "Multi.io",
  "BITEXBOOK",
  "Chiliz",
  "CoinEx",
];
