import { useStaticQuery, graphql } from "gatsby";
import { DEFI_GLOBAL_ENDPOINT } from "../../../data/coingecko-endpoints";
import { DefiGlobalResult } from "../../../types/api-results/defi-global";
import { mapDefiGlobal } from "../../utils/data-mappings/map-defi-global";
import { DefiGlobalQuery } from "../../../graphql-types";
import { FETCH_INTERVAL } from "../../constants/data-fetch";
import { useAxios } from "./use-axios";

export const useDefiGlobal = () => {
  const staticData = useStaticQuery<DefiGlobalQuery>(
    graphql`
      query DefiGlobal {
        defiGlobal {
          defi_market_cap
          eth_market_cap
          defi_to_eth_ratio
          trading_volume_24h
          defi_dominance
          top_coin_name
          top_coin_defi_dominance
        }
      }
    `
  );

  const { data, error, loading, timestamp } = useAxios<{
    data: DefiGlobalResult;
  }>(DEFI_GLOBAL_ENDPOINT, {
    refetchInterval: FETCH_INTERVAL,
  });

  const rawData = data?.data || staticData?.defiGlobal || undefined;

  return {
    data: mapDefiGlobal(rawData),
    error,
    loading,
    timestamp,
  };
};
