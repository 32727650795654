import {
  DefiGlobalResult,
  DefiGlobalMapped,
} from "../../../types/api-results/defi-global";
import { toNumber } from "../format-number";

export const mapDefiGlobal = (
  input: DefiGlobalResult | null | undefined
): DefiGlobalMapped | undefined => {
  if (!input) {
    return undefined;
  }

  return {
    defiMarketCap: toNumber(input.defi_market_cap),
    ethMarketCap: toNumber(input.eth_market_cap),
    defiToEthRatio: toNumber(input.defi_to_eth_ratio),
    tradingVolume24h: toNumber(input.trading_volume_24h),
    defiDominance: toNumber(input.defi_dominance),
    topCoinName: input.top_coin_name || undefined,
    topCoinDefiDominance: toNumber(input.top_coin_defi_dominance),
  };
};
