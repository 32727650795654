import { toNumber } from "../format-number";
import {
  MarketGlobalResult,
  MarketGlobalMapped,
} from "../../../types/api-results/market-global";

export const mapMarketGlobal = (
  input: MarketGlobalResult | null | undefined
): MarketGlobalMapped | undefined => {
  if (!input) {
    return undefined;
  }

  return {
    activeCryptocurrencies: toNumber(input.active_cryptocurrencies),
    upcomingIcos: toNumber(input.upcoming_icos),
    ongoingIcos: toNumber(input.ongoing_icos),
    endedIcos: toNumber(input.ended_icos),
    markets: toNumber(input.markets),
    totalMarketCap: input.total_market_cap || undefined,
    totalVolume: input.total_volume || undefined,
    marketCapPercentage: input.market_cap_percentage || undefined,
    marketCapChangePercentage24hUsd: toNumber(
      input.market_cap_change_percentage_24h_usd
    ),
    updatedAt: input.updated_at ? new Date(input.updated_at) : undefined,
  };
};
