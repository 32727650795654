import { toNumber } from "../format-number";
import {
  ExchangeListItemResult,
  ExchangeListItemMapped,
} from "../../../types/api-results/exchange-list-item";
import { DEFI_EXCHANGES } from "../../../data/defi-exchanges";

export const mapExchangeListItems = (
  input: ExchangeListItemResult[] | undefined
): ExchangeListItemMapped[] | undefined => {
  if (!input) {
    return undefined;
  }

  const mappedItems = input
    .map((item) => ({
      id: item.id,
      slug: item.id.toLowerCase(),
      name: item.name || undefined,
      yearEstablished: toNumber(item.year_established),
      country: item.country || undefined,
      description: item.description || undefined,
      url: item.url || undefined,
      image: item.image || undefined,
      hasTradingIncentive: item.has_trading_incentive || undefined,
      trustScore: toNumber(item.trust_score),
      trustScoreRank: toNumber(item.trust_score_rank),
      tradeVolume24hBtc: toNumber(item.trade_volume_24h_btc),
      tradeVolume24hBtcNormalized: toNumber(
        item.trade_volume_24h_btc_normalized
      ),
      isDex: !!item.name && DEFI_EXCHANGES.includes(item.name),
    }))
    .filter((item) => item.name) as ExchangeListItemMapped[];

  return mappedItems;
};
