export const DEFI_EXCHANGES = [
  "Uniswap (v2)",
  "Aave",
  "Compound Finance",
  "Curve Finance",
  "1inch",
  "0x Protocol",
  "Sushiswap",
  "Tokenlon",
  "BurgerSwap",
  "JustSwap",
  "Balancer",
  "Dodo",
  "Kyber Network",
  "Cybex DEX",
  "Mooniswap",
  "dYdX",
  "Nexus Mutual",
  "ViteX",
  "Synthetix Exchange",
  "Bancor Network",
  "Nash",
  "Cream Swap",
  "Binance DEX",
  "PoloniDEX",
  "Serum DEX",
  "BepSwap",
  "TomoDEX",
  "Bamboo Relay",
  "Uniswap (v1)",
  "Idex",
  "Newdex",
  "Switcheo",
  "TronTrade",
  "Loopring",
  "Deversifi",
  "Bisq",
  "StellarTerm",
  "Binance DEX (Mini)",
  "TokenSets",
  "Radar Relay",
  "OasisDEX",
  "Allbit",
  "ForkDelta",
  "Bitcratic",
  "Joyso",
  "Saturn Network",
  "dex.blue",
  "Neblidex",
  "Orderbook.io",
  "Everbloom",
  "Ethex",
  "Gnosis Protocol",
  "Blockonix",
  "DDEX",
  "Birake",
  "Dolomite",
];
